import axios from 'axios';

const sendATCTrackingForRR = async (payload) => {

  if (payload && payload.materialId) {
    axios.post("/insightweb/rr/tracking/atc", payload)
      .catch(error => {
         console.log('Error while sending ATC tracking data: ', error);
      })
  }

}

export default sendATCTrackingForRR;
